import React, { useMemo, useState, useEffect } from 'react';
import { mobileDemoBannerStyles as classes } from './styleObjects/MobileDemoBannerStyles';
import { Grid, Button } from '@mui/material';
import { PopoutButton } from '@components/PopoutCustomSection/PopoutButton';
import { useTranslation } from '@stateManagement/TranslationContext';
import { getTranslation } from '@helpers/languageTranslations';
import { isMobileAtom } from '@atoms/appSettings';
import { useAtom } from 'jotai';
import { selectedLanguageAtom } from '@atoms/appSettings';
import { getLanguageByCode } from '@helpers/languages';
import { MobileDemoBannerType } from '@models/mobileDemoBanner';

export function MobileDemoBanner(): JSX.Element | null {
    const { selectedLanguageTranslation } = useTranslation();
    const mobileDemoBannerTranslation = useMemo(() => {
        return getTranslation(selectedLanguageTranslation, 'mobileStickyBanner');
    }, [selectedLanguageTranslation]);
    const [isMobile] = useAtom(isMobileAtom);
    const [selectedLanguage] = useAtom(selectedLanguageAtom);
    const mobileDemoBannerType = getLanguageByCode(selectedLanguage)?.mobileDemoBannerType || 'link';
    const [scrolledToBottom, setScrolledToBottom] = useState(false);

    useEffect(() => {
        if (isMobile) {
            const timeoutId = setTimeout(() => {
                document.addEventListener('scroll', handleScroll);
            }, 5000);

            return () => {
                clearTimeout(timeoutId);
                document.removeEventListener('scroll', handleScroll);
            };
        }
    }, [isMobile]);

    function handleScroll(): void {
        if (window.innerHeight + window.scrollY >= document.body.offsetHeight - 300) {
            setScrolledToBottom(true);
        } else {
            setScrolledToBottom(false);
        }
    }

    function getBannerButton(type: MobileDemoBannerType): JSX.Element | null {
        switch (type) {
            case 'link':
                return (
                    <Button
                        variant='contained'
                        href={mobileDemoBannerTranslation.link}
                        color='primary'
                        css={classes.mobileDemoButton}
                    >
                        {mobileDemoBannerTranslation.text}
                    </Button>
                );
            case 'popoutButton':
                return (
                    <PopoutButton
                        text={mobileDemoBannerTranslation.text}
                        variant='contained'
                        customSectionKey='MultistepDemoForm'
                        displayMode='bg-popout'
                        css={classes.mobileDemoButton}
                    />
                );
            default:
                return null;
        }
    }

    return isMobile ? (
        <Grid
            css={[classes.mobileDemoButtonContainer, scrolledToBottom ? classes.hideBanner : undefined]}
            data-testid='mobile-banner-container-test-id'
        >
            {getBannerButton(mobileDemoBannerType)}
        </Grid>
    ) : null;
}
